
import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
import BackButton from '@/views/module/common/BackButton.vue';
import User from '@/app/com/main/module/business/user/bean/User';
import GroupMemberListEntity from '@/views/module/group/member/GroupMemberListEntity';
import BaseUtil from '@/app/lib/util/BaseUtil';
import UserInfoUtil from '@/app/com/main/common/util/UserInfoUtil';


@Component({
    components: {
        BackButton,
    },
})
export default class GroupMemberListPane extends Vue {
    private entity: GroupMemberListEntity = new GroupMemberListEntity();
    private items: User[] = [];
    @Prop({
        type: String,
        required: false,
        default: () => (''),
    })
    private groupId!: string;

    public mounted() {
        // no
        this.loadList();
    }

    private loadList() {
        const groupId = this.groupId;
        const entity = this.entity;
        entity.initialize(groupId);
    }

    private getNickname(user: User): string {
        let nickname = '';
        if (user) {
            nickname = this.entity.getNickname(user.id);
            if (BaseUtil.isEmpty(nickname)) {
                nickname = UserInfoUtil.getShowName(user);
            }
        }
        return nickname;
    }
}
